.header-container {
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;

  z-index: 100;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;

  height: 2rem;

  background-color: #333;

  h1 {
    position: relative;
  }
}

.logo::after {
  content: '';

  position: absolute;

  top: 100%;
  left: 0;

  width: 100%;
  height: 2px;

  background-color: #e6b029;

  transform: scaleX(0);
  transition: transform 0.3s;
  transform-origin: right;
}

.logo:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
