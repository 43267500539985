.card-height {
  max-height: 30rem;
}

@media only screen and (max-width: 768px) {
  .links {
    margin-top: 1rem;

    flex-direction: column;
    text-align: center;

    a {
      padding: 0.3rem;
    }

    a + a {
      margin-top: 0.5rem;
    }
  }
}
