@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: #333;
  color: #eee;
}

article {
  position: relative;

  z-index: 5;

  padding: 2rem;

  border-radius: 5px;

  background-color: #666;
  box-shadow: 15px 10px 10px rgba(34, 34, 34, 0.5);
}
