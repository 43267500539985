article {
  .elevator-pitch {
    margin-bottom: 2rem;

    p + p {
      margin-top: 1rem;
    }
  }

  .resume {
    padding: 0.5rem 1rem;

    border: 2px solid #e6b029;
    border-radius: 5px;

    font-weight: bold;

    &:hover {
      background-color: #e6b029;
      color: #333;
    }
  }
}

@media only screen and (max-width: 768px) {
  article {
    width: 90vw;
  }
}
