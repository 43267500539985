nav {
  li + li {
    margin-left: 10px;
  }

  li {
    position: relative;

    padding: 0 5px;

    border-radius: 3px;
  }

  .break {
    margin: 0 10px;

    width: 2px;
    height: 1rem;

    background-color: #999;
  }
}

li::before {
  content: '';

  position: absolute;

  z-index: -1;

  top: 100%;
  left: 0;

  width: 100%;
  height: 2px;

  border-radius: 3px;

  background-color: #e6b029;

  transform: scaleX(0);
  transition: transform 0.3s;
  transform-origin: right;
}

li:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.social {
  li {
    padding: 2px 5px;
  }
}

.menu {
  display: none;
}

@media only screen and (max-width: 768px) {
  nav {
    li + li {
      margin: 0;
    }

    .menu {
      display: block;
    }

    .navbar {
      flex-direction: column;

      position: absolute;

      top: 4rem;
      left: 0;

      width: 100%;

      padding: 1rem 0;

      background-color: #444;

      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.5s;

      .links {
        display: block;

        li {
          margin-top: 0.5rem;
        }
      }

      &.show {
        transform: scaleY(1);
      }

      .social {
        justify-content: space-around;

        margin-top: 1rem;
      }
    }

    .break {
      display: none;
    }
  }
}
